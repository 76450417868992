import gsap from 'gsap';
import { Vector2, CanvasTexture, PlaneGeometry, Mesh, Vector3, OrthographicCamera, WebGLRenderer, Scene, NearestFilter, RepeatWrapping, Texture, ShaderMaterial, MeshBasicMaterial } from 'three';

import Debugger from '@/webgl/utils/Debugger';
import math from '@/webgl/utils/math';
import ObjectPool from '@/webgl/utils/ObjectPool';
import Preloader from '../../Preloader';
import Stroke from './Stroke';
import Clouds from './Clouds';
import Events from '../../Events';

import config from '@/config.js'

import vertexShader from './shaders/lightning.vert.glsl';
import fragmentShader from './shaders/lightning.frag.glsl';

export default class Lightning {
    constructor() {
        this._canvas = document.createElement('canvas');

        this._maskCanvas = document.createElement('canvas');
        this._maskContext = this._maskCanvas.getContext('2d');

        this._width = null;
        this._height = null;

        this._isEnabled = false;

        this._renderer = this._createRenderer();
        this._camera = this._createCamera();
        this._scene = new Scene();

        this._cloudTexture = this._getCloudTexture();
        this._cloudObjectPool = new ObjectPool(10, Clouds);

        this._striked = false;
        this._strikeProgress = 0;
        this._trail = 0.04;
        this._speed = 0.16;
        this._strokeLength = 101;

        this._spawnInterval = 3;
        this._cursorSpawnInterval = 0.23;

        this._subdivisions = 11;
        this._maxSplits = 7;
        this._maxOffset = 270;

        this._mainBranchWidth = 1;
        this._sideBranchesWidth = 0.1;
        this._mainBranchAlpha = 1;
        this._sideBranchesAlpha = 0.2;

        this._mousePosition = { x: -1000, y: -1000 };
        this._transformOrigin = { x: 0, y: 0 };
        this._scale = 1;
        this._sceneOffset = 0;

        this._lightnings = [];
        this._cursorLightnings = [];

        this._originalValues = {
            spawnInterval: this._spawnInterval,
            trail: this._trail,
            speed: this._speed,
            sideBranchesAlpha: this._sideBranchesAlpha
        };

        this._bindHandlers();
        this._setupEventListeners();
        this._setupDebugGui();
        this._createQuad();
        this._resize();

        if (this._isMobile) {
            this._subdivisions = 8;
            this._maxSplits = 7;
            this._maxOffset = 170;
        }
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._canvas;
    }

    // prepare(callback) {
    //     this._isEnabled = true;
    //     gsap.delayedCall(1, () => {
    //         this._isEnabled = false;
    //         callback();
    //     });
    // }

    show() {
        this._start();
        this._startCursorLightning();

        this._isEnabled = true;
        this._timelineShow = new gsap.timeline();
        this._timelineShow.fromTo(this._canvas, 0.7, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut'}, 0);
    }

    hide(callback) {
        this._timelineShow = new gsap.timeline({ onComplete: () => {
            this._isEnabled = false;
            this._stop();
            this._stopCursorLightning();
            callback();
        } });
        this._timelineShow.to(this._canvas, 0.7, { alpha: 0, ease: 'sine.inOut'}, 0);
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
        this._onStrikeHandler = this._onStrikeHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
        this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        gsap.ticker.add(this._tickHandler);
        this._canvas.addEventListener('mousedown', this._mouseDownHandler);
        this._canvas.addEventListener('mouseup', this._mouseUpHandler);
        this._canvas.addEventListener('touchstart', this._touchStartHandler);
        this._canvas.addEventListener('touchend', this._touchEndHandler);
        this._canvas.addEventListener('mousemove', this._mouseMoveHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        gsap.ticker.remove(this._tickHandler);
        this._canvas.removeEventListener('mousedown', this._mouseDownHandler);
        this._canvas.removeEventListener('mouseup', this._mouseUpHandler);
        this._canvas.removeEventListener('touchstart', this._touchStartHandler);
        this._canvas.removeEventListener('touchend', this._touchEndHandler);
        this._canvas.removeEventListener('mousemove', this._mouseMoveHandler);
    }

    _createRenderer() {
        const renderer = new WebGLRenderer({
            canvas: this._canvas
        });
        renderer.setClearColor("#090909", 1);
        return renderer;
    }

    _createCamera() {
        const camera = new OrthographicCamera(0, 0, 0, 0, 1, 1000);
        camera.position.set(0, 0, 700);
        camera.lookAt(new Vector3());
        return camera;
    }

    _createQuad() {
        this._image = Preloader.get('lightning-texture');
        this._map = new Texture(this._image);
        this._map.needsUpdate = true;
        // this._map.minFilter = NearestFilter;
        // this._map.magFilter = NearestFilter;
        this._map.wrapS = RepeatWrapping;
        this._map.wrapT = RepeatWrapping;

        this._alphaMap = new CanvasTexture(this._maskCanvas);
        this._alphaMap.minFilter = NearestFilter;
        this._alphaMap.magFilter = NearestFilter;

        const geometry = new PlaneGeometry(1, 1);
        // const material = new MeshBasicMaterial({ map: this._alphaMap, wireframe: false, transparent: true });
        // const material = new MeshBasicMaterial({ map: this._map, alphaMap: this._alphaMap, transparent: true });
        const material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            transparent: true,
            uniforms: {
                uTexture: { value: this._map },
                uAlphaMap: { value: this._alphaMap }
            }
        });
        this._mesh = new Mesh(geometry, material);
        this._scene.add(this._mesh);
    }

    _getCloudTexture() {
        const texture = new Texture(Preloader.get('lightning-cloud-texture'));
        texture.needsUpdate = true;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        return texture;
    }

    _start() {
        const delay = this._spawnInterval * 0.5 + this._spawnInterval * 0.5 * Math.random();
        this._createStroke();
        this._spawnIntervalTimeout = gsap.delayedCall(delay, () => {
            this._start();
        });
    }

    _stop() {
        if (this._spawnIntervalTimeout) this._spawnIntervalTimeout.kill();
    }

    _update() {
        if (!this._isEnabled) return;

        this._maskContext.fillStyle = `rgba(0, 0, 0, ${this._trail})`;
        this._maskContext.fillRect(0, 0, this._width, this._height);

        // this._sceneOffset += 0.2;
        // this._scene.position.x = -this._width * 0.25 - this._sceneOffset;

        this._updateLightnings();
        if (!this._isMobile) {
            this._updateCursrorLightnings();
            this._updateCanvasTransformOrigin();
            this._updateTransform();
        }

        this._alphaMap.needsUpdate = true;
        this._renderer.render(this._scene, this._camera);
    }

    _updateLightnings() {
        let item;
        for (let i = 0, len = this._lightnings.length; i < len; i++) {
            item = this._lightnings[i];
            item.update();
        }
    }

    _updateCursrorLightnings() {
        let item;
        for (let i = 0, len = this._cursorLightnings.length; i < len; i++) {
            item = this._cursorLightnings[i];
            item.update();
        }
    }

    _updateCanvasTransformOrigin() {
        this._transformOrigin.x = math.lerp(this._transformOrigin.x, this._mousePosition.x, 0.05);
        this._transformOrigin.y = math.lerp(this._transformOrigin.y, this._mousePosition.y, 0.05);
        this._canvas.style.transformOrigin = `${this._transformOrigin.x}px ${this._transformOrigin.y}px`;
    }

    _updateTransform() {
        const transform = `scale(${this._scale})`;
        this._canvas.style.transform = transform;
        this._canvas.style.webkitTransform = transform;
    }

    _createStroke() {
        const stroke = new Stroke({
            context: this._maskContext,
            width: this._width,
            height: this._height,
            texture: null,
            speed: this._speed,
            strokeLength: this._strokeLength,
            subdivisions: this._subdivisions,
            maxSplits: this._maxSplits,
            maxOffset: this._maxOffset,
            mainBranchWidth: this._mainBranchWidth,
            sideBranchesWidth: this._sideBranchesWidth,
            mainBranchAlpha: this._mainBranchAlpha,
            sideBranchesAlpha: this._sideBranchesAlpha,
            showStrike: true,
            onStrike: this._onStrikeHandler,
            isMobile: this._isMobile
        });
        this._lightnings.push(stroke);
    }

    _createCursorStroke() {
        const startRadius = 250;

        // Startpoint
        const startX = this._mousePosition.x + this._sceneOffset + -startRadius * 0.5 + Math.random() * startRadius;
        const startY = this._mousePosition.y + -startRadius * 0.5 + Math.random() * startRadius;
        const startPoint = new Vector2(startX, startY);

        // Endpoint
        const radius = 70 + Math.random() * 150;
        const angle = Math.PI * 2 * Math.random();
        const endX = startX + radius * Math.cos(angle);
        const endY = startY + radius * Math.sin(angle);
        const endPoint = new Vector2(endX, endY);

        const stroke = new Stroke({
            context: this._maskContext,
            width: this._width,
            height: this._height,
            startPoint: startPoint,
            endPoint: endPoint,
            texture: this._texture,
            speed: 0.2,
            strokeLength: 1,
            subdivisions: 5,
            maxSplits: 0,
            maxOffset: 30,
            mainBranchWidth: 1,
            sideBranchesWidth: this._sideBranchesWidth,
            mainBranchAlpha: 0.5,
            sideBranchesAlpha: this._sideBranchesAlpha,
            showStrike: false
        });
        this._cursorLightnings.push(stroke);
    }

    _showClouds(startPoint, endPoint) {
        const clouds = this._cloudObjectPool.obtain({
            texture: this._cloudTexture
        });
        clouds.setPosition({
            startPoint: startPoint,
            endPoint: endPoint
        });
        clouds.addEventListener('complete', () => {
            this._cloudObjectPool.recycle(clouds);
        });
        this._scene.add(clouds);
        clouds.show();
    }

    _speedUp() {
        if (this._timelineSlowDown) this._timelineSlowDown.kill();

        this._timelineSpeedUp = new gsap.timeline();
        this._timelineSpeedUp.to(this, 0.1, {
            _trail: 0.09,
            _speed: 0.49,
            _sideBranchesAlpha: 0.6,
            onUpdate: () => {
                let item;
                for (let i = 0, len = this._lightnings.length; i < len; i++) {
                    item = this._lightnings[i];
                    item.speed = this._speed;
                    item.sideBranchesAlpha = this._sideBranchesAlpha;
                }
            }
        }, 0);
        this._timelineSpeedUp.to(this, 0.8, { _scale: 0.98, ease: 'power3.out' }, 0);

        this._spawnInterval = 0.64;
        if (this._spawnIntervalTimeout) this._spawnIntervalTimeout.kill();
        this._start();
    }

    _slowDown() {
        if (!this._originalValues) return;

        if (this._timelineSpeedUp) this._timelineSpeedUp.kill();

        this._timelineSlowDown = new gsap.timeline();
        this._timelineSlowDown.to(this, 0.1, {
            _spawnInterval: this._originalValues.spawnInterval,
            _trail: this._originalValues.trail,
            _speed: this._originalValues.speed,
            _sideBranchesAlpha: this._originalValues.sideBranchesAlpha,
            onUpdate: () => {
                let item;
                for (let i = 0, len = this._lightnings.length; i < len; i++) {
                    item = this._lightnings[i];
                    item.speed = this._speed;
                    item.sideBranchesAlpha = this._sideBranchesAlpha;
                }
            }
        }, 0);
 
        this._timelineSlowDown.to(this, 0.8, { _scale: 1 }, 0);
    }

    _startCursorLightning() {
        const delay = this._cursorSpawnInterval * 0.5 + this._cursorSpawnInterval * 0.5 * Math.random();
        this._createCursorStroke();
        this._cursorLightningInterval = gsap.delayedCall(delay, () => {
            this._startCursorLightning();
        });
    }

    _stopCursorLightning() {
        if (this._cursorLightningInterval) this._cursorLightningInterval.kill();
    }

    /**
     * Resize
     */
    _resize() {
        this._width = window.innerWidth * 1.1;
        this._height = window.innerHeight * 1.1;

        this._isMobile = window.innerWidth < config.mobileLimit;

        this._resizeCanvas();
        this._resizeMaskCanvas();
        this._resizeCamera();
        this._resizeRenderer();
        this._resizeQuad();
        this._resizeMap();
    }

    _resizeCanvas() {
        this._canvas.width = this._width;
        this._canvas.height = this._height;

        const x = -(this._width - window.innerWidth) * 0.5;
        const y = -(this._height - window.innerHeight) * 0.5;

        this._canvas.style.position = 'absolute';
        this._canvas.style.left = `${x}px`;
        this._canvas.style.top = `${y}px`;
    }

    _resizeMaskCanvas() {
        this._maskCanvas.width = this._width;
        this._maskCanvas.height = this._height;
    }

    _resizeRenderer() {
        this._renderer.setPixelRatio(1);
        this._renderer.setSize(this._width, this._height, false);
    }

    _resizeCamera() {
        this._camera.left = this._width / -2;
        this._camera.right = this._width / 2;
        this._camera.top = this._height / 2;
        this._camera.bottom = this._height / -2;
        this._camera.updateProjectionMatrix();
    }

    _resizeQuad() {
        this._mesh.scale.x = this._width;
        this._mesh.scale.y = this._height;
    }

    _resizeMap() {
        const imageWidth = this._image.width;
        const imageHeight = this._image.height;
        const x = (this._width) / imageWidth;
        const y = this._height / imageHeight;
        this._map.repeat.set(x, y);
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Lightning');
        this._debugGui.add(this, '_spawnInterval', 0.01, 5, 0.01).name('spawnInterval').listen();
        this._debugGui.add(this, '_trail', 0, 1, 0.01).name('trail').listen();
        this._debugGui.add(this, '_speed', 0, 1, 0.01).name('speed').listen().onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.speed = this._speed;
            }
        });
        this._debugGui.add(this, '_strokeLength', 1, 200, 1).name('strokeLength').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.strokeLength = this._strokeLength;
            }
        });
        this._debugGui.add(this, '_subdivisions', 1, 15, 1).name('subdivisions').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.subdivisions = this._subdivisions;
            }
        });
        this._debugGui.add(this, '_maxSplits', 0, 20, 1).name('maxSplits').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.maxSplits = this._maxSplits;
            }
        });
        this._debugGui.add(this, '_maxOffset', 0, 500, 1).name('maxOffset').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.maxOffset = this._maxOffset;
            }
        });
        this._debugGui.add(this, '_mainBranchWidth', 0, 5, 0.1).name('mainBranchWidth').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.mainBranchWidth = this._mainBranchWidth;
            }
        });
        this._debugGui.add(this, '_sideBranchesWidth', 0, 5, 0.1).name('sideBranchesWidth').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.sideBranchesWidth = this._sideBranchesWidth;
            }
        });
        this._debugGui.add(this, '_mainBranchAlpha', 0, 1, 0.01).name('mainBranchAlpha').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.mainBranchAlpha = this._mainBranchAlpha;
            }
        });
        this._debugGui.add(this, '_sideBranchesAlpha', 0, 1, 0.01).name('sideBranchesAlpha').onChange(() => {
            let item;
            for (let i = 0, len = this._lightnings.length; i < len; i++) {
                item = this._lightnings[i];
                item.sideBranchesAlpha = this._sideBranchesAlpha;
            }
        });
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _tickHandler() {
        this._update();
    }

    _onStrikeHandler(startPoint, endPoint) {
        this._showClouds(startPoint, endPoint);
    }

    _mouseDownHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 5);
        this._speedUp();
    }

    _mouseUpHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._slowDown();
    }

    _touchStartHandler() {
        this._isMouseDown = true;
        this._speedUp();
    }

    _touchEndHandler() {
        this._isMouseDown = false;
        this._slowDown();
    }

    _mouseMoveHandler(e) {
        this._mousePosition.x = e.clientX;
        this._mousePosition.y = e.clientY;
    }
}
