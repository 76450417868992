<template>
  <section class="shops" v-if="contentLoaded">
    <div
      class="shops__container"
      :class="{ 'scroll-wrapper': !$root.isMobile }"
    >
      <div
        class="horizontal-wrapper"
        :class="{ 'js-scroll-elem': !$root.isMobile }"
      >
        <div class="shops__cover horizontal-block js-white-header-section">
          <NavMobileChocolate v-if="$root.isMobile" :isWhite="true" />
          <router-link
            :to="`/${$i18n.locale}/chocolate`"
            class="shops__cover__logo"
            v-if="!$root.isMobile"
            ref="logo"
          >
            <img
              src="../../assets/images/chocolate/logo-2.png"
              alt="Patrick roger new logo green"
            />
          </router-link>

          <h2
            class="shop__cover__title title-chocolate is-white"
            v-html="content.title"
            ref="title"
          ></h2>
          <div
            class="shops__cover__bg"
            ref="bg"
            :style="{ 'background-image': `url(${content.cover_image.url}` }"
          ></div>
        </div>

        <div class="shops__lines-container js-white-header-section">
          <CanvasBackground
            scene="lines"
            type="horizontal"
            amplitude="18"
            v-if="showCanvas"
          />

          <section
            class="shops__regions__titles pad-l-sm pad-r-xs horizontal-block"
            v-if="additionalContentLoaded"
          >
            <div class="shops__regions__titles__header">
              <template v-if="$root.isMobile">
                <h2
                  class="shops__regions__title quote-chocolate is-white"
                  v-html="content.shops_title"
                ></h2>
              </template>
              <ParallaxeHorizontalElem v-else :amount="0.4" :direction="-1">
                <h2
                  class="shops__regions__title quote-chocolate is-white"
                  v-html="content.shops_title"
                ></h2>
              </ParallaxeHorizontalElem>
            </div>

            <div
              class="shops__regions__list"
              v-if="additionalContentLoaded"
              ref="list"
            >
              <template v-if="!columnsReady">
                <ul
                  class="shops__regions__list__column"
                  v-for="(item, index) in content.shop_groups"
                  :key="index"
                  ref="column"
                >
                  <h3
                    class="
                      shops__regions__list__column__title
                      title-chocolate
                      is-white
                    "
                  >
                    {{ item.title }}
                  </h3>
                  <li
                    v-for="(item2, index2) in item.shops"
                    :key="index2"
                    class="shops__regions__item"
                    :class="{
                      'is-active': index2 === indexItem && index === indexGroup,
                    }"
                  >
                    <a
                      href="#"
                      v-html="item2.acf.name"
                      class="
                        shops__regions__item__elem
                        subtitle-chocolate
                        is-white
                        js-item-elem
                      "
                      :data-column="index"
                      :data-elem="index2"
                      ref="colItem"
                    ></a>
                  </li>
                </ul>
              </template>
              <template v-else>
                <div
                  class="shops__regions__list__column-2"
                  v-for="(item, index) in columns"
                  :key="index"
                >
                  <ul
                    class="shops__regions__list__column-2__elem"
                    v-for="(item2, index2) in item"
                    :key="index2"
                    v-html="item2.innerHTML"
                  />
                </div>
              </template>
            </div>
          </section>

          <section
            class="shops__shop pad-r-xs horizontal-block"
            v-if="selectedShop && additionalContentLoaded"
          >
            <ChocolateShopsCard
              :columns="true"
              :isBlack="true"
              :previewImage="selectedShop.acf.preview_image"
              :title="selectedShop.acf.cover_title"
              :street="selectedShop.acf.street"
              :zipcode="selectedShop.acf.zipcode"
              :city="selectedShop.acf.city"
              :phone="selectedShop.acf.phone"
              :infos="selectedShop.acf.shop_infos"
              :btnLabel="content.shops_shop_label"
              :shopID="selectedShop.ID"
              ref="ChocolateShopsCard"
            />
          </section>

          <FooterChocolate
            class="horizontal-block"
            :showBackgroundLines="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";

import EventHub from "@/utils/EventHub.js";

import store from "@/store/store.js";

import SmoothScrollHorizontalMixin from "@/mixins/SmoothScrollHorizontalMixin.js";
import API from "@/mixins/API.js";
import Page from "@/mixins/Page.js";
import MobileScroll from "@/mixins/MobileScroll.js";

import FooterChocolate from "@/components/FooterChocolate.vue";
import ChocolateShopsCard from "@/components/ChocolateShopsCard.vue";
import CanvasBackground from "@/components/CanvasBackground.vue";
import NavMobileChocolate from "@/components/NavMobileChocolate.vue";
import ParallaxeHorizontalElem from "@/components/ParallaxeHorizontalElem.vue";

export default {
  name: "Shops",

  mixins: [SmoothScrollHorizontalMixin, API, Page, MobileScroll],

  components: {
    FooterChocolate,
    ChocolateShopsCard,
    CanvasBackground,
    NavMobileChocolate,
    ParallaxeHorizontalElem,
  },

  data() {
    return {
      windowObj: {},
      isHorizontal: true,
      indexGroup: 0,
      indexItem: 0,
      additionalContentLoaded: false,
      columns: [],
      columnsReady: false,
      showCanvas: false,
    };
  },

  computed: {
    selectedShop() {
      return this.content.shop_groups[this.indexGroup].shops[this.indexItem];
    },
  },

  created() {
    if (!store.state.peasant) {
      store.getStories(this.$i18n.locale, this.onLoadedStories);
    } else {
      this.onLoadedStories();
    }

    this.addEventListeners();
  },

  mounted() {},

  destroyed() {
    this.removeEventListeners();
    this.removeColItemsListeners();
  },

  methods: {
    createColumns() {
      this.columnsReady = false;

      const listHeight = this.$refs.list.getBoundingClientRect().height;

      let currentH = 0;
      let currentCol = [];

      for (let i = 0; i < this.$refs.column.length; i++) {
        const colH = this.$refs.column[i].getBoundingClientRect().height;

        if (currentH === 0 && colH > listHeight) {
          currentCol = [this.$refs.column[i]];
          this.columns.push(currentCol);
          currentH = 0;
          currentCol = [];
        } else if (currentH + colH > listHeight) {
          this.columns.push(currentCol);
          currentH = colH;
          currentCol = [this.$refs.column[i]];
        } else {
          currentCol.push(this.$refs.column[i]);
          currentH += colH;
        }

        // if its the last, push column as it is
        if (i + 1 >= this.$refs.column.length) {
          this.columns.push(currentCol);
        }
      }

      this.columnsReady = true;

      this.$nextTick(() => {
        this.addColItemsListeners();
      });
    },
    playTimeline() {
      this.tl = gsap.timeline({
        onComplete: () => {
          // console.log('on complete tl')
          EventHub.$emit("window:on-resize", {
            width: window.innerWidth,
            height: window.innerHeight,
          });
        },
      });

      this.tl.to(
        this.$refs.title,
        {
          opacity: 1,
          duration: 1,
          ease: "power3.in",
        },
        0.3
      );

      this.tl.to(
        this.$refs.bg,
        {
          scale: 1,
          duration: 3,
          delay: 0,
          ease: "Power3.easeOut",
        },
        0
      );

      this.tl.to(
        ".shops__cover__logo",
        {
          opacity: 1,
          duration: 1,
          ease: "power3.in",
        },
        0.3
      );
    },

    onLoadedStories() {
      this.content = store.state.shops;
      this.contentLoaded = true;

      this.fetchAdditionalContent();
    },
    /**
     *
     * Listeners
     */
    addColItemsListeners() {
      const $elems = this.$el.querySelectorAll(".js-item-elem");

      $elems.forEach((item) => {
        item.addEventListener("click", (ev) => {
          this.onClickShop(ev, item.dataset.column, item.dataset.elem);
        });
      });
    },
    removeColItemsListeners() {
      const $elems = this.$el.querySelectorAll(".js-item-elem");

      $elems.forEach((item) => {
        item.removeEventListener("click", (ev) => {
          this.onClickShop(ev, item.dataset.column, item.dataset.elem);
        });
      });
    },
    addEventListeners() {
      EventHub.$on("window:on-resize", this.onResize);
    },

    removeEventListeners() {
      EventHub.$off("window:on-resize", this.onResize);
    },

    fetchAdditionalContent() {
      const endpoints = [];

      for (let i = 0; i < this.content.shop_groups.length; i++) {
        for (let j = 0; j < this.content.shop_groups[i].shops.length; j++) {
          const endpoint = {
            exisitingPath: ["shop_groups", i, "shops", j],
            url: `/shops/${this.content.shop_groups[i].shops[j].ID}?lang=${this.$i18n.locale}`,
          };

          endpoints.push(endpoint);
        }
      }

      // Load all
      this.fetchMultiple(endpoints, () => {
        this.additionalContentLoaded = true;

        if (!this.$route.query.transition || this.$root.firstPage) {
          this.onMounted();
        }

        this.$nextTick(() => {
          EventHub.$emit("nav-fixed-chocolate:init");

          this.createColumns();

          this.playTimeline();

          if (!this.$root.isMobile) {
            this.SmoothScrollHorizontalMixin_init();
          } else {
            this.MobileScroll_init();
          }
        });

        this.onResize();

        setTimeout(() => {
          this.showCanvas = true;
        }, 1500);
      });
    },

    /**
     *
     * Events
     */
    onResize(windowObj) {
      this.windowObj = windowObj;
    },

    onClickShop(event, indexGroup, indexItem) {
      event.preventDefault();

      this.scroll.target.x =
        this.$refs.ChocolateShopsCard.$el.offsetLeft +
        (window.innerWidth - this.$refs.ChocolateShopsCard.$el.offsetWidth);

      this.indexGroup = indexGroup;
      this.indexItem = indexItem;
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  display: inline-block;
}

.shops {
  @media (max-width: $breakpoints.md) {
    overflow-y: hidden;
    position: relative;
    height: 100%;
  }
}

.shops__container {
  background-color: $grey;
}

.shops__cover {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.shops__cover__logo {
  z-index: 3;
  position: fixed;
  top: 80px;
  left: 85px;
  width: 128px;
  opacity: 0;
  will-change: opacity, transform;

  @media (max-width: $breakpoints.xl) {
    left: 50px;
    width: 110px;
  }

  @media (max-width: $breakpoints.lg) {
    width: 100px;
  }

  img {
    display: block;
    width: 100%;
  }
}

.shops__cover__bg {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  transform: scale(1.125);
  transform-origin: 50% 50%;
  will-change: transform;
}

.shop__cover__title {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  will-change: opacity;
}

.shops__lines-container {
  position: relative;
  display: inline-block;
  background: $black;

  @media (max-width: $breakpoints.md) {
    height: 100%;
  }
}

.shops__regions__titles {
  height: 100vh;
  vertical-align: top;
  color: $white;
  background: $black;

  @media (max-width: $breakpoints.md) {
    position: relative;
    top: 0;
  }
}

.shops__regions__title {
  width: 750px;
  padding-top: 8vh;
  padding-bottom: 5vh;

  @media (max-width: $breakpoints.md) {
    padding-top: 10vh;
    padding-bottom: 8vh;
    font-size: 40px;
  }
}

.shops__regions__list {
  height: 65vh;

  @media (max-width: $breakpoints.md) {
    height: 70vh;
  }
}

.shops__regions__list__column__title {
  margin-bottom: 1.5rem;

  @media (max-width: $breakpoints.md) {
    font-size: 26px;
  }
}

.shops__regions__list__column {
  float: left;
  vertical-align: top;
  width: 340px;
  margin-right: 130px;
  padding-bottom: 10vh;

  @media (max-width: $breakpoints.md) {
    padding-bottom: 7.5vh;
  }
}

.shops__regions__list__column-2 {
  display: inline-block;
  vertical-align: top;
  margin-right: 130px;
  height: 100%;

  @media (max-width: $breakpoints.md) {
    margin-right: 100px;
  }

  &:last-child {
    margin-right: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    li {
      align-items: center;
      height: 40px;
    }
  }
}

.shops__regions__list__column-2__elem {
  padding-bottom: 10vh;

  @media (max-width: $breakpoints.md) {
    padding-bottom: 7.5vh;
  }
}

.shops__regions__item {
  &:first-child {
    .shops__regions__item__elem {
      padding-top: 0;
    }
  }

  &:last-child {
    .shops__regions__item__elem {
      padding-bottom: 0;
    }
  }
}

.shops__regions__item__elem {
  display: block;
  text-transform: uppercase;
  opacity: 0.4;
  transition: opacity 0.4s $ease-out-quart;

  &:hover {
    opacity: 0.8;
  }

  .is-active & {
    opacity: 1;
  }

  /deep/ br {
    display: none;
  }

  @media (max-width: $breakpoints.md) {
    padding: 10px 0;
    font-size: 16px;
  }
}

.shops__shop {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  color: $white;
  background: $black;

  .shop_card {
    @media (max-width: $breakpoints.md) {
      width: 450px;
    }
  }
}

.shops__shop__image {
  height: 50vh;
}
</style>