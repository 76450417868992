import { gsap } from 'gsap';

import math from '@/webgl/utils/math';
import Debugger from '@/webgl/utils/Debugger';
import Events from '../../Events';

import Particle from './Particle';
import { noise, noiseDetail } from './Noise';

const AMOUNT = 10000;

export default class Flowfield {
    constructor() {
        this._container = document.createElement('div');
        this._container.style.position = 'relative';
        this._container.style.width = '100%';
        this._container.style.height = '100%';
        this._container.style.perspective = '800px';

        this._canvas = document.createElement('canvas');
        this._canvas.style.transformOrigin = 'center center';
        this._container.appendChild(this._canvas);

        this._context = this._canvas.getContext('2d');

        this._width = null;
        this._height = null;
        this._transformOrigin = { x: 0, y: 0 };
        this._transformTranslate = { x: 0, y: 0 };
        this._mousePosition = {
            viewport: { x: 0, y: 0 },
            screen: { x: 0, y: 0 },
            transformed: { x: 0, y: 0 },
        };
        this._particles = [];
        this._isMouseDown = false;

        this._rotation = {
            x: 0,
            y: 0,
            z: 0
        };
        this._rotationAmountIdleX = 0.03;
        this._rotationAmountActiveX = 0.111;
        this._rotationAmountX = this._rotationAmountIdleX;
        this._rotationAmountIdleY = 0.03;
        this._rotationAmountActiveY = 0.111;
        this._rotationAmountY = this._rotationAmountIdleY;
        this._rotationLerpAmount = 0.03;
        this._rotationSpeedZ = 0.005;
        this._scale = 1;
        this._scaleSpeed = 0.00001;

        this._trail = 0.051; //0.02;
        this._speed = 0.72;
        this._lifeMultiplier = 3;
        this._gridSize = 3;
        this._gridIncrement = 0.032;
        this._noiseOctaves = 2;
        this._noiseFallof = 0.5;
        this._saturation = 61;
        this._lightness = 60;

        this._originalValues = {
            speed: this._speed,
            trail: this._trail
        };

        this._mouseParticleIntervalCount = 0;

        this._resize();
        this._bindHandlers();
        this._setupEventListeners();
        this._setupDebugGui();
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._container;
    }

    show() {
        this._generate();

        this._timelineShow = new gsap.timeline();
        this._timelineShow.fromTo(this._canvas, 0.7, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut'}, 0);
    }

    hide(callback) {
        this._timelineShow = new gsap.timeline({ onComplete: callback });
        this._timelineShow.to(this._canvas, 0.7, { alpha: 0, ease: 'sine.inOut'}, 0);
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
        this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchMoveHandler = this._touchMoveHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        gsap.ticker.add(this._tickHandler);

        // Mouse
        window.addEventListener('mousemove', this._mouseMoveHandler);
        window.addEventListener('mousedown', this._mouseDownHandler);
        window.addEventListener('mouseup', this._mouseUpHandler);

        // Touch
        window.addEventListener('touchstart', this._touchStartHandler);
        window.addEventListener('touchmove', this._touchMoveHandler);
        window.addEventListener('touchend', this._touchEndHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        gsap.ticker.remove(this._tickHandler);

        // Mouse
        window.removeEventListener('mousemove', this._mouseMoveHandler);
        window.removeEventListener('mousedown', this._mouseDownHandler);
        window.removeEventListener('mouseup', this._mouseUpHandler);

        // Touch
        window.removeEventListener('touchstart', this._touchStartHandler);
        window.removeEventListener('touchmove', this._touchMoveHandler);
        window.removeEventListener('touchend', this._touchEndHandler);
    }

    _setNoiseDetail() {
        noiseDetail(this._noiseOctaves, this._noise);
    }

    _generate() {
        this._context.fillStyle = 'rgb(9, 9, 9)';
        this._context.fillRect(0, 0, this._width, this._height);

        this._setNoiseDetail();

        this._rows = Math.floor(this._height / this._gridSize) + 2;
        this._cols = Math.floor(this._width / this._gridSize) + 2;
        this._flowfield = new Array(this._rows * this._cols);
        // this._particles = this._createParticles();

        this._updateFlowfield();
    }

    _createParticles() {
        const particles = [];
        for (let i = 0; i < AMOUNT; i++) {
            particles.push(new Particle({
                canvasWidth: this._width,
                canvasHeight: this._height,
                row: this._rows,
                cols: this._cols,
                gridSize: this._gridSize,
                flowfield: this._flowfield,
                speed: this._speed,
                lifeMultiplier: this._lifeMultiplier,
                saturation: this._saturation,
                lightness: this._lightness
            }));
        }
        return particles;
    }

    _createParticle(x, y, alpha) {
        this._particles.push(new Particle({
            x: x,
            y: y,
            alpha: alpha,
            canvasWidth: this._width,
            canvasHeight: this._height,
            row: this._rows,
            cols: this._cols,
            gridSize: this._gridSize,
            flowfield: this._flowfield,
            speed: this._speed,
            lifeMultiplier: this._lifeMultiplier,
            saturation: this._saturation,
            lightness: this._lightness
        }));
    }

    _update() {
        this._time += 0.01;

        this._rotation.z += this._rotationSpeedZ;
        // this._scale += this._scaleSpeed;

        this._clear();
        this._checkParticlesLife();

        if (this._particles.length < AMOUNT) {
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
            this._createParticle(null, null, 0.4);
        }

        this._updateCursorRotation();
        this._addMouseParticles();
        this._updateParticles();
        this._updateTransform();

        // this._updateCanvasTransformOrigin();
        // this._drawDebugCursor();
    }

    _updateCursorRotation() {
        const x = this._mousePosition.screen.x;
        const y = this._mousePosition.screen.y;

        const centerX = this._width * 0.5;
        const centerY = this._height * 0.5;

        const cursorRotation = this._rotateCursor(centerX, centerY, x, y, this._rotation.z);
        this._mousePosition.transformed.x = cursorRotation.x;
        this._mousePosition.transformed.y = cursorRotation.y;
    }

    _drawDebugCursor() {
        this._context.fillStyle = 'red';
        this._context.beginPath();
        this._context.arc(this._mousePosition.transformed.x, this._mousePosition.transformed.y, 10, 0, Math.PI * 2);
        this._context.fill();
    }

    _clear() {
        // this._context.clearRect(0, 0, this._width, this._height);
        this._context.fillStyle = `rgba(9, 9, 9, ${this._trail})`;
        // this._context.fillStyle = 'rgba(0, 0, 0, 1)';
        this._context.fillRect(0, 0, this._width, this._height);
    }

    _checkParticlesLife() {
        let item;

        for (let i = this._particles.length - 1; i >= 0; i--) {
            item = this._particles[i];
            if (item.isDead) {
                this._particles.splice(i, 1);
            }
        }
    }

    _addMouseParticles() {
        this._mouseParticleIntervalCount++;

        const interval = this._isMouseDown ? 1 : 3;
        if (this._mouseParticleIntervalCount % interval === 0) {
            const radius = Math.random() * 50;
            const angle = Math.random() * Math.PI * 2;
            const x = this._mousePosition.transformed.x + radius * Math.cos(angle);
            const y = this._mousePosition.transformed.y + radius * Math.sin(angle);
            this._createParticle(x, y, 1);
            this._createParticle(x, y, 1);
        }
    }

    _updateFlowfield() {
        let yoff = 0;
        for (let y = 0; y <= this._rows; y++) {
            let xoff = 0;
            for (let x = 0; x <= this._cols; x++) {
                let angle = noise(xoff, yoff, this._time) * Math.PI * 3;
                let index = x + y * this._cols;

                this._flowfield[index] = angle;
                xoff += this._gridIncrement;
            }
            yoff += this._gridIncrement;
        }
    }

    _updateParticles() {
        let particle;
        for (let i = 0, len = this._particles.length; i < len; i++) {
            particle = this._particles[i];
            particle.lightness = this._lightness;
            particle.update();
            particle.draw(this._context);
        }
    }

    _updateTransform() {
        const halfViewportWidth = this._viewportWidth * 0.5;
        const halfViewportHeight = this._viewportHeight * 0.5;
        const offsetX = (this._mousePosition.viewport.x - halfViewportWidth) / halfViewportWidth;
        const offsetY = (this._mousePosition.viewport.y - halfViewportHeight) / halfViewportHeight;

        const x = this._transformTranslate.x;
        const y = this._transformTranslate.y;

        const rotationX = math.map(offsetY, -1, 1, -this._rotationAmountX, this._rotationAmountX);
        const rotationY = math.map(offsetX, -1, 1, this._rotationAmountY, -this._rotationAmountY);
        this._rotation.x = math.lerp(this._rotation.x, rotationX, this._rotationLerpAmount);
        this._rotation.y = math.lerp(this._rotation.y, rotationY, this._rotationLerpAmount);

        const transform = `translate(${x}px, ${y}px) rotateX(${this._rotation.x}rad) rotateY(${this._rotation.y}rad) rotateZ(${this._rotation.z}deg) scale(${this._scale})`;
        this._canvas.style.transform = transform;
        this._canvas.style.webkitTransform = transform;
    }

    // _updateCanvasTransformOrigin() {
    //     this._transformOrigin.x = math.lerp(this._transformOrigin.x, this._mousePosition.x, 0.08);
    //     this._transformOrigin.y = math.lerp(this._transformOrigin.y, this._mousePosition.y, 0.08);
    //     this._canvas.style.transformOrigin = `${this._transformOrigin.x}px ${this._transformOrigin.y}px`;
    // }

    _speedUp() {
        if (!this._originalValues) return;

        if (this._timelineSlowDown) this._timelineSlowDown.kill();

        this._timelineSpeedUp = new gsap.timeline();
        this._timelineSpeedUp.to(this, 0.5, {
            _speed: this._originalValues.speed,
            _trail: this._originalValues.trail,
            _rotationAmountX: this._rotationAmountIdleX,
            _rotationAmountY: this._rotationAmountIdleY,
            _saturation: 61,
            _lightness: 60,
            onUpdate: () => {
                let item;
                for (let i = 0, len = this._particles.length; i < len; i++) {
                    item = this._particles[i];
                    item.speed = this._speed;
                }
            }
        }, 0);
        this._timelineSpeedUp.to(this, 0.8, { _scale: 1 }, 0);
    }

    _slowDown() {
        if (this._timelineSpeedUp) this._timelineSpeedUp.kill();

        this._timelineSlowDown = new gsap.timeline();
        this._timelineSlowDown.to(this, 0.4, {
            _speed: 0.15,
            _trail: 0.039,
            _rotationAmountX: this._rotationAmountActiveX,
            _rotationAmountY: this._rotationAmountActiveY,
            _saturation: 100,
            _lightness: 70,
            onUpdate: () => {
                let item;
                for (let i = 0, len = this._particles.length; i < len; i++) {
                    item = this._particles[i];
                    item.speed = this._speed;
                }
            }
        }, 0);
        this._timelineSlowDown.to(this, 1, { _scale: 0.97, ease: 'power3.out' }, 0);
    }
h
    _rotateCursor(cx, cy, x, y, angle) {
        var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return { x: nx, y: ny };
    }

    /**
     * Resize
     */
    _resize() {
        this._viewportWidth = window.innerWidth;
        this._viewportHeight = window.innerHeight;

        const distance = math.distance({ x: 0, y: 0}, { x: this._viewportWidth * 0.5, y: this._viewportHeight * 0.5 });
        const size = distance * 2.2;

        this._width = size;
        this._height = size;

        this._canvas.width = this._width;
        this._canvas.height = this._height;

        this._transformTranslate = {
            x: Math.round((this._viewportWidth - size) * 0.5),
            y: Math.round((this._viewportHeight - size) * 0.5)
        };
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Flowfield');
        this._debugGui.add(this, '_trail', 0, 1, 0.001).name('trail').listen();
        this._debugGui.add(this, '_speed', 0, 2, 0.02);
        this._debugGui.add(this, '_lifeMultiplier', 0, 5, 0.02);
        this._debugGui.add(this, '_gridSize', 1, 100, 0.1);
        this._debugGui.add(this, '_gridIncrement', 0, 0.2, 0.001);
        this._debugGui.add(this, '_noiseOctaves', 0, 10, 1);
        this._debugGui.add(this, '_noiseFallof', 0, 1, 0.01);
        this._debugGui.add(this, '_rotationSpeedZ', -0.1, 0.1, 0.001);
        this._debugGui.add(this, '_rotationAmountActiveX', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountIdleX', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountActiveY', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountIdleY', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationLerpAmount', 0, 0.5, 0.001);
        // this._debugGui.add(this, '_scaleSpeed', 0, 0.001, 0.00001);
        this._debugGui.add(this._particles, 'length').listen().name('particle amount');
        this._debugGui.add(this, '_generate');
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _tickHandler() {
        this._update();
    }

    _mouseDownHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 3);
        this._slowDown();
    }

    _mouseUpHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUp();
    }

    _mouseMoveHandler(e) {
        const x = (this._width - this._viewportWidth) * 0.5 + e.clientX;
        const y = (this._height - this._viewportHeight) * 0.5 + e.clientY;
        this._mousePosition.viewport.x = e.clientX;
        this._mousePosition.viewport.y = e.clientY;
        this._mousePosition.screen.x = x;
        this._mousePosition.screen.y = y;
    }

    // Touch
    _touchStartHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 3);
        this._slowDown();
    }

    _touchMoveHandler(e) {
        const touch = e.touches[0];
        const x = (this._width - this._viewportWidth) * 0.5 + touch.clientX;
        const y = (this._height - this._viewportHeight) * 0.5 + touch.clientY;
        this._mousePosition.viewport.x = touch.clientX;
        this._mousePosition.viewport.y = touch.clientY;
        this._mousePosition.screen.x = x;
        this._mousePosition.screen.y = y;
    }

    _touchEndHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUp();
    }
}
