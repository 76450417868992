import { gsap } from 'gsap';

import Debugger from '@/webgl/utils/Debugger';
import math from '@/webgl/utils/math';

import Plant from './Plant';
import Preloader from '../../Preloader';
import Events from '../../Events';

export default class Growth {
    constructor() {
        this._container = document.createElement('div');
        this._container.style.position = 'relative';
        this._container.style.width = '100%';
        this._container.style.height = '100%';
        this._container.style.perspective = '800px';

        this._canvas = document.createElement('canvas');
        this._canvas.style.transformOrigin = 'center center';
        this._container.appendChild(this._canvas);

        const noise = document.createElement('div');
        noise.style.position = 'absolute';
        noise.style.top = '0';
        noise.style.left = '0';
        noise.style.width = '100%';
        noise.style.height = '100%';
        noise.style.backgroundImage = 'url(/images/intro/noise.jpg)';
        noise.style.backgroundRepeat = 'repeat';
        noise.style.opacity = '0.019';
        // this._container.appendChild(noise);

        this._context = this._canvas.getContext('2d');

        this._width = null;
        this._height = null;
        this._plants = [];
        this._timeStart = performance.now();
        this._transformTranslate = { x: 0, y: 0 };
        this._mousePosition = {
            viewport: { x: 0, y: 0 },
            screen: { x: 0, y: 0 },
            transformed: { x: 0, y: 0 },
        };
        this._scale = 1;

        this._rotation = {
            x: 0,
            y: 0,
            z: 0,
        };
        this._rotationAmountIdleX = 0.02;
        this._rotationAmountActiveX = 0.06;
        this._rotationAmountX = this._rotationAmountIdleX;
        this._rotationAmountIdleY = 0.02;
        this._rotationAmountActiveY = 0.06;
        this._rotationAmountY = this._rotationAmountIdleY;
        this._rotationLerpAmount = 0.03;
        this._rotationSpeedZ = 0.005;

        this._zoom = 0;
        this._zoomSpeed = 0.0001;
        this._cursorRadius = 40;
        this._isEnabled = false;
        this._cursorPlantInterval = 0;

        this._plantConfig = {
            minSize: 10,
            maxSize: 70,
            minRadius: 20,
            maxRadius: 50,
            maxDelay: 7, // 7
            duration: 1,
            noiseSpeed: 0.01,
            colorAlpha: 0.015, // 0.01 || 0.015
            speed: 10,
        };

        this._bindHandlers();
        this._setupEventListeners();
        this._setupDebugGui();
        this._resize();
        this._generate();
    }

    destroy() {
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    getElement() {
        return this._container;
    }

    prepare(callback) {
        this._isEnabled = true;
        gsap.delayedCall(1.5, () => {
            this._isEnabled = false;
            callback();
        });
    }

    show() {
        this._isEnabled = true;

        this._timelineShow = new gsap.timeline();
        this._timelineShow.fromTo(this._canvas, 0.7, { alpha: 0 }, { alpha: 1, ease: 'sine.inOut' }, 0);
    }

    hide(callback) {
        this._timelineShow = new gsap.timeline({
            onComplete: () => {
                this._isEnabled = false;

                callback();
            },
        });
        this._timelineShow.to(this._canvas, 0.7, { alpha: 0, ease: 'sine.inOut' }, 0);
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._resizeHandler = this._resizeHandler.bind(this);
        this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchMoveHandler = this._touchMoveHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
        this._tickHandler = this._tickHandler.bind(this);
    }

    _setupEventListeners() {
        window.addEventListener('resize', this._resizeHandler);
        window.addEventListener('mousemove', this._mouseMoveHandler);
        window.addEventListener('mousedown', this._mouseDownHandler);
        window.addEventListener('mouseup', this._mouseUpHandler);

        // Touch
        window.addEventListener('touchstart', this._touchStartHandler);
        window.addEventListener('touchmove', this._touchMoveHandler);
        window.addEventListener('touchend', this._touchEndHandler);
        
        gsap.ticker.add(this._tickHandler);
    }

    _removeEventListeners() {
        window.removeEventListener('resize', this._resizeHandler);
        window.removeEventListener('mousemove', this._mouseMoveHandler);
        window.removeEventListener('mousedown', this._mouseDownHandler);
        window.removeEventListener('mouseup', this._mouseUpHandler);

        // Touch
        window.removeEventListener('touchstart', this._touchStartHandler);
        window.removeEventListener('touchmove', this._touchMoveHandler);
        window.removeEventListener('touchend', this._touchEndHandler);
        
        gsap.ticker.remove(this._tickHandler);
    }

    _reset() {
        this._clear();
        this._timeStart = performance.now();
        this._plants = [];
    }

    _generate() {
        this._reset();

        let x;
        let y;
        let size;

        const amount = this._calcAmount();

        for (let i = 0; i < amount; i++) {
            x = this._width * Math.random();
            y = this._height * Math.random();
            size = this._plantConfig.minSize + Math.random() * (this._plantConfig.maxSize - this._plantConfig.minSize);

            // context.fillStyle = 'rgba(255, 50, 0, 0.01)';
            // context.beginPath();
            // context.fillRect(0, 0, 300, 300);
            this._plants.push(
                new Plant({
                    context: this._context,
                    position: { x, y },
                    size: size,
                    config: this._plantConfig,
                    texture: Preloader.get('growth-circle'),
                    color: 'rgba(49, 229, 209, 1)',
                    globalAlpha: 0.7,
                    scaleModifier: 0.001,
                })
            );
        }
    }

    _update() {
        if (!this._isEnabled) return;

        const time = (performance.now() - this._timeStart) / 1000;

        this._rotation.z += this._rotationSpeedZ;
        // this._zoom += this._zoomSpeed;

        // this._clear();
        this._updateCursorRotation();
        this._findClosestPlant();
        if (this._isMouseDown) this._spawnPlantAtCursor();
        this._updatePlants(time);
        this._updateTransform();
        // this._drawDebugCursor();
    }

    _clear() {
        this._context.clearRect(0, 0, this._width, this._height);
    }

    _findClosestPlant() {
        let plant;
        let distance;
        for (let i = 0, len = this._plants.length; i < len; i++) {
            plant = this._plants[i];
            distance = math.distance(plant.position, this._mousePosition.transformed);

            if (distance < this._cursorRadius) {
                // plant._scale = 0.1;
                plant._colorAlpha += 0.01;
                plant._colorAlpha = gsap.utils.clamp(0, 0.1, plant._colorAlpha);
            }
        }
    }

    _spawnPlantAtCursor() {
        this._cursorPlantInterval++;

        if (this._cursorPlantInterval % 7 === 0) {
            const size = this._plantConfig.minSize + Math.random() * (this._plantConfig.maxSize - this._plantConfig.minSize);
            const radius = 20 + 200 * Math.random();
            const angle = Math.PI * 2 * Math.random();
            const x = this._mousePosition.transformed.x + radius * Math.cos(angle);
            const y = this._mousePosition.transformed.y + radius * Math.sin(angle);

            this._plants.push(
                new Plant({
                    context: this._context,
                    position: {
                        x: x,
                        y: y,
                    },
                    size: size, // 10
                    config: this._plantConfig,
                    texture: Preloader.get('growth-circle'),
                    color: 'rgba(49, 229, 209, 1)',
                    globalAlpha: 10,
                    scaleModifier: 0.005,
                })
            );
        }
    }

    _updatePlants(time) {
        let plant;
        for (let i = 0, len = this._plants.length; i < len; i++) {
            plant = this._plants[i];
            plant.update(time);
            plant.draw();
        }
    }

    _updateTransform() {
        const halfViewportWidth = this._viewportWidth * 0.5;
        const halfViewportHeight = this._viewportHeight * 0.5;
        const offsetX = (this._mousePosition.viewport.x - halfViewportWidth) / halfViewportWidth;
        const offsetY = (this._mousePosition.viewport.y - halfViewportHeight) / halfViewportHeight;

        const x = this._transformTranslate.x;
        const y = this._transformTranslate.y;

        const rotationX = math.map(offsetY, -1, 1, -this._rotationAmountX, this._rotationAmountX);
        const rotationY = math.map(offsetX, -1, 1, this._rotationAmountY, -this._rotationAmountY);
        this._rotation.x = math.lerp(this._rotation.x, rotationX, this._rotationLerpAmount);
        this._rotation.y = math.lerp(this._rotation.y, rotationY, this._rotationLerpAmount);

        const transform = `translate(${x}px, ${y}px) rotateX(${this._rotation.x}rad) rotateY(${this._rotation.y}rad) rotateZ(${this._rotation.z}deg) scale(${this._scale})`;
        this._canvas.style.transform = transform;
        this._canvas.style.webkitTransform = transform;
    }

    _updateCursorRotation() {
        const x = this._mousePosition.screen.x;
        const y = this._mousePosition.screen.y;

        const centerX = this._width * 0.5;
        const centerY = this._height * 0.5;

        const cursorRotation = this._rotateCursor(centerX, centerY, x, y, this._rotation.z);
        this._mousePosition.transformed.x = cursorRotation.x;
        this._mousePosition.transformed.y = cursorRotation.y;
    }

    _drawDebugCursor() {
        const context = this._context;
        context.fillStyle = 'rgba(255, 0, 0, 0.01)';
        context.beginPath();
        context.arc(this._mousePosition.transformed.x, this._mousePosition.transformed.y, this._cursorRadius, 0, Math.PI * 2);
        context.fill();
    }

    _calcAmount() {
        const gridSize = 100;
        const rows = Math.round(this._width / gridSize);
        const cols = Math.round(this._height / gridSize);
        const amount = rows * cols;
        return amount;
    }

    _rotateCursor(cx, cy, x, y, angle) {
        var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = cos * (x - cx) + sin * (y - cy) + cx,
            ny = cos * (y - cy) - sin * (x - cx) + cy;
        return { x: nx, y: ny };
    }

    _speedUp() {
        if (this._timelineSlowDown) this._timelineSlowDown.kill();

        this._timelineSpeedUp = new gsap.timeline();
        this._timelineSpeedUp.to(
            this,
            0.5,
            {
                _rotationAmountX: this._rotationAmountIdleX,
                _rotationAmountY: this._rotationAmountIdleY,
            },
            0
        );
        this._timelineSpeedUp.to(this, 0.8, { _scale: 1 }, 0);
    }

    _slowDown() {
        if (this._timelineSpeedUp) this._timelineSpeedUp.kill();

        this._timelineSlowDown = new gsap.timeline();
        this._timelineSlowDown.to(
            this,
            0.4,
            {
                _rotationAmountX: this._rotationAmountActiveX,
                _rotationAmountY: this._rotationAmountActiveY,
            },
            0
        );
        this._timelineSlowDown.to(this, 1, { _scale: 0.96, ease: 'power3.out' }, 0);
    }

    /**
     * Resize
     */
    _resize() {
        this._viewportWidth = window.innerWidth;
        this._viewportHeight = window.innerHeight;

        const distance = math.distance({ x: 0, y: 0 }, { x: this._viewportWidth * 0.5, y: this._viewportHeight * 0.5 });
        const size = distance * 2.2;

        this._width = size;
        this._height = size;
        this._resizeCanvas();
        this._calcCanvasOffset();

        this._transformTranslate = {
            x: Math.round((this._viewportWidth - size) * 0.5),
            y: Math.round((this._viewportHeight - size) * 0.5),
        };
    }

    _resizeCanvas() {
        this._canvas.width = this._width;
        this._canvas.height = this._height;
    }

    _calcCanvasOffset() {
        this._offsetX = Math.round((this._viewportWidth - this._width) * 0.5);
        this._offsetY = Math.round((this._viewportHeight - this._height) * 0.5);
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        this._debugGui = Debugger.gui.addFolder('Growth');
        this._debugGui.add(this, '_rotationSpeedZ', -0.1, 0.1, 0.001);
        this._debugGui.add(this, '_rotationAmountActiveX', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountIdleX', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountActiveY', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationAmountIdleY', -0.5, 0.5, 0.001);
        this._debugGui.add(this, '_rotationLerpAmount', 0, 0.5, 0.001);
        // this._debugGui.add(this, '_zoomSpeed', 0, 0.001, 0.00001);
        this._debugGui.add(this._plantConfig, 'minSize', 1, 150, 1);
        this._debugGui.add(this._plantConfig, 'maxSize', 1, 150, 1);
        this._debugGui.add(this._plantConfig, 'minRadius', 1, 150, 1);
        this._debugGui.add(this._plantConfig, 'maxRadius', 1, 150, 1);
        this._debugGui.add(this._plantConfig, 'maxDelay', 1, 20, 1);
        this._debugGui.add(this._plantConfig, 'duration', 0.1, 20, 0.1);
        this._debugGui.add(this._plantConfig, 'noiseSpeed', 0, 1, 0.001);
        this._debugGui.add(this._plantConfig, 'colorAlpha', 0, 1, 0.001);
        this._debugGui.add(this._plantConfig, 'speed', 0, 10, 0.001);
        this._debugGui.add(this, '_generate');
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _resizeHandler() {
        this._resize();
    }

    _mouseMoveHandler(e) {
        const x = (this._width - this._viewportWidth) * 0.5 + e.clientX;
        const y = (this._height - this._viewportHeight) * 0.5 + e.clientY;
        this._mousePosition.viewport.x = e.clientX;
        this._mousePosition.viewport.y = e.clientY;
        this._mousePosition.screen.x = x;
        this._mousePosition.screen.y = y;
    }

    _mouseDownHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 1);
        this._slowDown();
    }

    _mouseUpHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUp();
    }

    _tickHandler() {
        this._update();
    }

    // Touch
    _touchStartHandler() {
        this._isMouseDown = true;
        Events.dispatchEvent('mouse:down', 1);
        this._slowDown();
    }

    _touchMoveHandler(e) {
        const touch = e.touches[0];
        const x = (this._width - this._viewportWidth) * 0.5 + touch.clientX;
        const y = (this._height - this._viewportHeight) * 0.5 + touch.clientY;
        this._mousePosition.viewport.x = touch.clientX;
        this._mousePosition.viewport.y = touch.clientY;
        this._mousePosition.screen.x = x;
        this._mousePosition.screen.y = y;
    }

    _touchEndHandler() {
        this._isMouseDown = false;
        Events.dispatchEvent('mouse:up');
        this._speedUp();
    }
}
