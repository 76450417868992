export default class Segment {
    constructor({ startPoint, endPoint, split, branchId = null, id, parent }) {
        this._startPoint = startPoint;
        this._endPoint = endPoint;
        this._split = split;
        this._branchId = branchId;
        this._progress = 0;
        this._id = id;
        this._parent = null;
        this._parent = parent;
    }

    /**
     * Getters & Setters
     */
    get startPoint() {
        return this._startPoint;
    }

    set startPoint(value) {
        this._startPoint = value;
    }

    get endPoint() {
        return this._endPoint;
    }

    set endPoint(value) {
        this._endPoint = value;
    }

    get split() {
        return this._split;
    }

    set split(value) {
        this._split = value;
    }

    get progress() {
        return this._progress;
    }

    set progress(value) {
        this._progress = value;
    }

    get branchId() {
        return this._branchId;
    }

    set branchId(value) {
        this._branchId = value;
    }

    get parent() {
        return this._parent;
    }

    set parent(value) {
        this._parent = value;
    }

    get id() {
        return this._id
    }
}