/* eslint-disable */
const BOUNDS_OFFSET = 0;

export default class Particle {
    constructor({ x, y, alpha, canvasWidth, canvasHeight, rows, cols, gridSize, flowfield, speed, lifeMultiplier, saturation, lightness }) {
        this._x = x;
        this._y = y;
        this._canvasWidth = canvasWidth;
        this._canvasHeight = canvasHeight;

        this._rows = rows;
        this._cols = cols;
        this._gridSize = gridSize;
        this._flowfield = flowfield;
        this._speed = speed;
        this._lifeMultiplier = lifeMultiplier;

        this._startLocation = this._getStartLocation();
        this._location = this._startLocation;
        this._velocity = { x: 0, y: 0 };
        this._isDead = false;
        this._base = this._calcBase();
        this._life = this._calcLife();
        this._history = [];
        this._alpha = alpha;
        this._saturation = saturation;
        this._lightness = lightness;
    }

    /**
     * Getters & Setters
     */
    get isDead() {
        return this._isDead;
    }

    get speed() {
        return this._speed;
    }

    set speed(value) {
        this._speed = value;
    }

    get saturation() {
        return this._saturation;
    }

    set saturation(value) {
        this._saturation = value;
    }

    get lightness() {
        return this._lightness;
    }

    set lightness(value) {
        this._lightness = value;
    }

    /**
     * Public
     */
    update() {
        this._checkOutOfBounds();
        this._follow();

        this._history.push({ x: this._location.x, y: this._location.y });
        this._location.x += this._velocity.x;
        this._location.y += this._velocity.y;
        this._life--;
    }

    draw(context) {
        const last = this._history.length - 1;

        if (Math.random() > 0.5) {

            const size = Math.random() > 0.5 ? 1 : 2;
            context.beginPath();
            const x1 = this._history[last].x; // + (Math.random() > 0.5 ? 1 : 0);
            const y1 = this._history[last].y; // + (Math.random() > 0.5 ? 1 : 0);
            context.rect(x1, y1, size, size);

            context.globalAlpha = this._alpha;
            context.fillStyle = `hsl(171, ${this._saturation}%, ${this._lightness}%)`;
            // context.strokeStyle = 'white';
            context.fill();
            context.globalAlpha = 1;
        }





        // context.moveTo(this._history[last].x, this._history[last].y);
        // context.lineTo(this._history[last-10].x, this._history[last-10].y);
        // for (let i = last; i > 0; i--) {

        // }

        // context.strokeStyle = `hsl(171, 61%, ${this._life * 1.1}%)`;


        // if (this._history.length > this._life) {
        //   this._history.splice(0, 1);
        // }
    }

    /**
     * Private
     */
    _getStartLocation() {
        if (this._x && this._y) {
            return {
                x: this._x,
                y: this._y
            };
        } else {
            return {
                x: -BOUNDS_OFFSET + Math.random() * (this._canvasWidth + BOUNDS_OFFSET * 2),
                y: -BOUNDS_OFFSET + Math.random() * (this._canvasHeight + BOUNDS_OFFSET * 2)
            }
        }
    }

    _calcLife() {
        return this._randomIntFromInterval(50, 100) * this._lifeMultiplier;
        // return 100;
    }

    _calcBase() {
        return (1 + Math.random()) * -3 * 0.5;
    }

    _follow() {
        const x = ~~(this._location.x / this._gridSize);
        const y = ~~(this._location.y / this._gridSize);
        const index = x + y * this._cols;
        const angle = this._flowfield[index];

        this._velocity.x = this._base * Math.cos(angle) * this._speed;
        this._velocity.y = this._base * Math.sin(angle) * this._speed;
    }

    _checkOutOfBounds() {
        if (
            this._location.x > this._canvasWidth + BOUNDS_OFFSET ||
            this._location.x < -BOUNDS_OFFSET ||
            this._location.y > this._canvasHeight + BOUNDS_OFFSET ||
            this._location.y < -BOUNDS_OFFSET
        ) {
            this._isDead = true;
        }

        if (this._life <= 0) {
            this._isDead = true;
            // this._location = this._getStartLocation();
            // this._life = this._calcLife();
            // this._history.length = 0;
        }
    }

    _randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}
