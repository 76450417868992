// Misc
import gsap from 'gsap';

// Utils
import Debugger from '@/webgl/utils/Debugger';
import EventDispatcher from '@/webgl/utils/EventDispatcher';

// Scenes
import Lines from '@/webgl/intro/scenes/lines';
import Lightning from '@/webgl/intro/scenes/lightning';
import Rain from '@/webgl/intro/scenes/rain';
import Foot from '@/webgl/intro/scenes/foot';
import Ripples from '@/webgl/intro/scenes/ripples';
import Flowfield from '@/webgl/intro/scenes/flowfield';
import Growth from '@/webgl/intro/scenes/growth';
import Crystalize from '@/webgl/intro/scenes/crystalize';

const SCENES = [
    { name: 'lines', class: Lines, duration: 20 },
    { name: 'lightning', class: Lightning, duration: 12 },
    { name: 'rain', class: Rain, duration: 12 },
    // { name: 'foot', class: Foot, duration: 16 },
    { name: 'ripples', class: Ripples, duration: 12 },
    { name: 'flowfield', class: Flowfield, duration: 12 },
    { name: 'growth', class: Growth, duration: 12 },
    { name: 'crystalize', class: Crystalize, duration: 12 },
];

const FIRST_SCENE_DURATION = 16;
// const RESUME_DELAY = 5;
const DELAY_FIRST_SCENE = 2;

export default class Intro extends EventDispatcher {
    constructor({ container }) {
        super();

        this._container = container;
        this._data = this._shuffleArray(SCENES);
        this._autoPlay = true;
        this._isTransitionActive = false;
        this._currentScene = null;
        this._currentSceneIndex = this._getCurrentSceneIndex();
        this._currentSceneName = this._getCurrentSceneName();
        this._lastTouchEvent = null;
        this._isFirstSceneShown = false;
        this._startTime = performance.now();

        this._bindHandlers();
        this._setupEventListeners();
        this._setupDebugGui();

        gsap.delayedCall(DELAY_FIRST_SCENE, () => {
            this._switchScene(this._currentSceneIndex);
        });
    }

    destroy() {
        if (this._currentScene) this._currentScene.destroy();
        this._removeEventListeners();
        this._removeDebugGui();
    }

    /**
     * Public
     */
    next() {
        this._next();
    }

    /**
     * Private
     */
    _bindHandlers() {
        this._keyDownHandler = this._keyDownHandler.bind(this);
        this._touchStartHandler = this._touchStartHandler.bind(this);
        this._touchEndHandler = this._touchEndHandler.bind(this);
        this._touchMoveHandler = this._touchMoveHandler.bind(this);
        this._mouseDownHandler = this._mouseDownHandler.bind(this);
        this._mouseUpHandler = this._mouseUpHandler.bind(this);
        this._timelinePlayUpdateHandler = this._timelinePlayUpdateHandler.bind(this);
        this._next = this._next.bind(this);
    }

    _setupEventListeners() {
        document.body.addEventListener('keydown', this._keyDownHandler);
        document.body.addEventListener('touchstart', this._touchStartHandler);
        document.body.addEventListener('touchmove', this._touchMoveHandler);
        document.body.addEventListener('touchend', this._touchEndHandler);
        document.body.addEventListener('mousedown', this._mouseDownHandler);
        document.body.addEventListener('mouseup', this._mouseUpHandler);
    }

    _removeEventListeners() {
        document.body.removeEventListener('keydown', this._keyDownHandler);
        document.body.removeEventListener('touchstart', this._touchStartHandler);
        document.body.removeEventListener('touchmove', this._touchMoveHandler);
        document.body.removeEventListener('touchend', this._touchEndHandler);
        document.body.removeEventListener('mousedown', this._mouseDownHandler);
        document.body.removeEventListener('mouseup', this._mouseUpHandler);
    }

    _shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    _getCurrentSceneIndex() {
        const scene = this._getSceneUrlParam();
        for (let i = 0, len = this._data.length; i < len; i++) {
            if (this._data[i].name === scene) return i;
        }
        return 0;
    }

    _getCurrentSceneName() {
        return this._getScene(this._currentSceneIndex).name;
    }

    _getSceneUrlParam() {
        if (Debugger.gui) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('scene');
        } else {
            return null;
        }
    }

    _updateUrlParam() {
        if (!Debugger.gui) return;
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('scene', this._getCurrentSceneName());
        window.history.pushState(null, null, '?' + urlParams.toString());
    }

    _getScene(index) {
        return this._data[index];
    }

    _getSceneByName(name) {
        for (let i = 0, len = this._data.length; i < len; i++) {
            if (this._data[i].name === name) return this._data[i];
        }
    }

    _switchScene(index) {
        if (this._currentScene) this._currentScene.destroy();
        this.dispatchEvent('scene:start', index);
        const scene = this._getScene(index);
        this._currentSceneName = scene.name;
        this._currentSceneIndex = index;
        this._createScene(scene);
    }

    _createScene(scene) {
        this._isTransitionActive = false;
        this._currentScene = new scene.class();
        if (this._currentScene.show) this._currentScene.show();
        if (this._autoPlay) this._play();
        this._switchElement();
        this._updateUrlParam();

        this._startTime = performance.now();
    }

    _switchElement() {
        if (this._activeElement) {
            this._activeElement.parentElement.removeChild(this._activeElement);
        }
        this._activeElement = this._currentScene.getElement();
        this._container.appendChild(this._activeElement);
    }

    _play() {
        const scene = this._getScene(this._currentSceneIndex);
        const duration = this._isFirstSceneShown ? scene.duration : FIRST_SCENE_DURATION;
        this._isFirstSceneShown = true;

        if (this._timelinePlay) this._timelinePlay.kill();
        this._timelinePlay = new gsap.timeline({ onUpdate: this._timelinePlayUpdateHandler });
        this._timelinePlay.call(this._next, null, duration);
    }

    _stop() {
        if (this._timelinePlay) this._timelinePlay.kill();
    }

    _pause() {
        if (this._timelinePlay) this._timelinePlay.pause();
    }

    _resume() {
        if (!this._autoPlay) return;
        if (this._timelinePlay) this._timelinePlay.play();
        // this._timelinePlay = new gsap.timeline({ onUpdate: this._timelinePlayUpdateHandler });
        // this._timelinePlay.call(this._next, null, RESUME_DELAY);
    }

    _next() {
        this._goto(this._currentSceneIndex + 1);
    }

    _previous() {
        this._goto(this._currentSceneIndex - 1);
    }

    _goto(index) {
        // index = math.clamp(index, 0, this._data.length - 1);
        if (index >= this._data.length) {
            index = 0;
        } else if (index < 0) {
            index = this._data.length - 1;
        }

        if (index === this._currentSceneIndex) return;

        if (this._isTransitionActive) return;
        this._isTransitionActive = true;

        if (this._currentScene.hide) {
            setTimeout(() => {
                this.dispatchEvent('scene:finished');
            }, 16);

            this._currentScene.hide(() => {
                this._switchScene(index);
            });
        } else {
            this._switchScene(index);
        }
    }

    /**
     * Debug
     */
    _setupDebugGui() {
        const gui = Debugger.gui;
        if (!gui) return;

        const scenes = [];
        for (let i = 0, len = this._data.length; i < len; i++) {
            scenes.push(this._data[i].name);
        }

        this._debugGui = Debugger.gui.addFolder('Controller');
        this._debugGui
            .add(this, '_currentSceneName', scenes)
            .name('scene')
            .listen()
            .onChange(() => {
                const scene = this._getSceneByName(this._currentSceneName);
                this._switchScene(this._data.indexOf(scene));
            });
        this._debugGui.add(this, '_previous').name('previous');
        this._debugGui.add(this, '_next').name('next');
        this._debugGui
            .add(this, '_autoPlay')
            .name('autoplay')
            .onChange(() => {
                this._autoPlay ? this._play() : this._stop();
            });
        this._debugGui.open();
    }

    _removeDebugGui() {
        if (this._debugGui) Debugger.gui.removeFolder(this._debugGui);
    }

    /**
     * Handlers
     */
    _keyDownHandler(e) {
        switch (e.keyCode) {
            case 39: // right arrow key
                this._next();
                break;
            case 37: // left arrow key
                this._previous();
                break;
        }
    }

    _touchStartHandler(e) {
        this._lastTouchEvent = e;
    }

    _touchEndHandler() {
        // if (this._lastTouchEvent.touches[0].clientX > window.innerWidth * 0.5) {
        //     this._next();
        // } else {
        //     this._previous();
        // }
    }

    _touchMoveHandler(e) {
        this._lastTouchEvent = e;
    }

    _mouseDownHandler() {
        this._pause();
    }

    _mouseUpHandler() {
        this._resume();
    }

    _timelinePlayUpdateHandler() {
        this.dispatchEvent('progress', this._timelinePlay.progress());
    }
}
