import gsap from 'gsap';
import { Vector2 } from 'three';
import Segment from './Segment';

export default class Stroke {
    constructor({ context, width, height, startPoint, endPoint, texture, speed, strokeLength, subdivisions, maxSplits, maxOffset, mainBranchWidth, sideBranchesWidth, mainBranchAlpha, sideBranchesAlpha, showStrike, onStrike, isMobile }) {
        this._context = context;
        this._width = width;
        this._height = height;
        this._texture = texture;

        this._startPoint = startPoint;
        this._endPoint = endPoint;

        // Settings
        this._subdivisions = subdivisions;
        this._maxSplits = maxSplits;
        this._maxOffset = maxOffset;
        this._speed = this._randomizeSpeed(speed);
        this._strokeLength = strokeLength;
        this._mainBranchWidth = mainBranchWidth;
        this._sideBranchesWidth = sideBranchesWidth;
        this._mainBranchAlpha = mainBranchAlpha;
        this._sideBranchesAlpha = sideBranchesAlpha;
        this._showStrike = showStrike;
        this._onStrikeCallback = onStrike;
        this._isMobile = isMobile;

        this._progress = 0;
        this._striked = false;
        this._strikeProgress = 0;

        this._create();
    }

    /**
     * Getters & Setters
     */
    get speed() {
        return this._speed;
    }

    set speed(value) {
        this._speed = this._randomizeSpeed(value);
    }

    get strokeLength() {
        return this._strokeLength;
    }

    set strokeLength(value) {
        this._strokeLength = value;
    }

    get mainBranchWidth() {
        return this._mainBranchWidth;
    }

    set mainBranchWidth(value) {
        this._mainBranchWidth = value;
    }

    get sideBranchesWidth() {
        return this._sideBranchesWidth;
    }

    set sideBranchesWidth(value) {
        this._sideBranchesWidth = value;
    }

    get mainBranchAlpha() {
        return this._mainBranchAlpha;
    }

    set mainBranchAlpha(value) {
        this._mainBranchAlpha = value;
    }

    get sideBranchesAlpha() {
        return this._sideBranchesAlpha;
    }

    set sideBranchesAlpha(value) {
        this._sideBranchesWidth = value;
    }

    /**
     * Public
     */
    update() {
        // if (this._progress >= 1) return;

        this._progress += 0.1 * this._speed;
        this._progress = this._clamp(this._progress, 0, 1);

        this._draw();
    }

    /**
     * Private
     */
    _create() {
        const startSegment = this._createStartSegment();
        const segments = this._generateSegments(startSegment);

        this._branches = this._splitBranches(segments);
        this._branches = this._sortBranchesSegments(this._branches);
    }

    _createStartSegment() {
        if (!this._startPoint && !this._endPoint) {
            // Startpoint
            const startX = this._width * 0.2 + this._width * 0.6 * Math.random();
            const startY = 0;
            this._startPoint = new Vector2(startX, startY);

            // Endpoint
            const offset = this._isMobile ? 100 : 600;
            const endX = startX + -offset + offset * 2 * Math.random();
            const endY = this._height;
            this._endPoint = new Vector2(endX, endY);
        }

        // Segment
        return new Segment({ startPoint: this._startPoint, endPoint: this._endPoint });
    }

    _generateSegments(startSegment) {
        let segments = [];
        segments.push(startSegment);

        let splits = 0;
        let offset = this._maxOffset;

        let segment;
        let id;
        let newSegents;
        let previousSegment;

        let branchLibs;

        for (let i = 0; i < this._subdivisions; i++) {

            id = 0;
            segment = null;
            newSegents = [];

            branchLibs = {
                main: [],
                subbranches: {}
            };

            for (var j = 0, len = segments.length; j < len; j++) {

                segment = segments[j];

                if (segment.split) {
                    const sub = branchLibs.subbranches[segment.branchId];
                    if (sub) {
                        previousSegment = sub[sub.length - 1];
                    } else {
                        branchLibs.main[branchLibs.main.length - 1];
                    }
                } else {
                    previousSegment = branchLibs.main[branchLibs.main.length - 1];
                }

                const p1 = segment.startPoint;
                const p2 = segment.endPoint;

                const dx = p1.x - p2.x;
                const dy = p1.y - p2.y;
                const normal = new Vector2(dy, -dx);
                normal.normalize();

                const p3 = new Vector2().lerpVectors(p1, p2, 0.5);
                const o = offset * Math.random();
                const s = Math.random() > 0.5 ? -1 : 1;
                p3.add(normal.multiplyScalar(o * s));

                id++;

                const part1 = new Segment({
                    startPoint: p1,
                    endPoint: p3,
                    split: segment.split,
                    branchId: segment.branchId,
                    id: id,
                    parent: previousSegment
                });
                newSegents.push(part1);

                if (part1.split) {
                    if (!branchLibs.subbranches[part1.branchId]) branchLibs.subbranches[part1.branchId] = [];
                    branchLibs.subbranches[part1.branchId].push(part1);
                } else {
                    branchLibs.main.push(part1);
                }

                id++;

                const part2 = new Segment({
                    startPoint: p3,
                    endPoint: p2,
                    split: segment.split,
                    branchId: segment.branchId,
                    id: id,
                    parent: part1
                });
                newSegents.push(part2);

                if (part2.split) {
                    if (!branchLibs.subbranches[part2.branchId]) branchLibs.subbranches[part2.branchId] = [];
                    branchLibs.subbranches[part2.branchId].push(part2);
                } else {
                    branchLibs.main.push(part2);
                }

                if (splits < this._maxSplits && Math.random() > 0.2) {

                    const direction = p3.clone().sub(p1);

                    let randomSmallAngle = (Math.PI * 2) * Math.random();
                    // randomSmallAngle = Math.random() > 0.5 ? randomSmallAngle : -randomSmallAngle;

                    const splitEnd = direction.clone().rotateAround(new Vector2(0, 0), randomSmallAngle);
                    splitEnd.multiplyScalar(1 + Math.random() * 0.3).add(p3);
                    // splitEnd.multiplyScalar(0.2).add(p3);

                    id++;

                    const part3 = new Segment({
                        startPoint: p3,
                        endPoint: splitEnd,
                        split: true,
                        branchId: splits,
                        id: id,
                        parent: part1
                    });
                    newSegents.push(part3);

                    if (part3.split) {
                        if (!branchLibs.subbranches[part3.branchId]) branchLibs.subbranches[part3.branchId] = [];
                        branchLibs.subbranches[part3.branchId].push(part3);
                    } else {
                        branchLibs.main.push(part3);
                    }

                    splits++;
                }
            }

            segments = newSegents;

            offset /= 1.9;
        }

        return segments;
    }

    _splitBranches(segments) {
        const main = [];
        const branches = [];

        let segment;
        for (let i = 0, len = segments.length; i < len; i++) {
            segment = segments[i];
            if (segment.split) {
                if (!branches[segment.branchId]) branches[segment.branchId] = [];
                branches[segment.branchId].push(segment);
            } else {
                main.push(segment);
            }
        }

        return { main, branches }
    }

    _sortBranchesSegments(branches) {
        branches.main = this._sortSegments(branches.main);
        for (let i = 0, len = branches.branches.length; i < len; i++) {
            branches.branches[i] = this._sortSegments(branches.branches[i]);
        }
        return branches;
    }

    _sortSegments(segments) {
        segments.sort((a, b) => {
            if (a.id > b.id) {
                return 1;
            } else {
                return -1;
            }
        });
        return segments;
    }

    _draw() {
        if (this._progress === 1) {
            if (this._showStrike) {
                this._strike();
                this._drawStrike(this._context);
            }
        } else {
            this._drawBranch(this._context, this._branches.main, this._progress, 1);
            for (let i = 0, len = this._branches.branches.length; i < len; i++) {
                this._drawBranch(this._context, this._branches.branches[i], this._progress, 0.035);
            }
        }
    }

    _strike() {
        if (this._striked) return;
        this._striked = true;

        this._strikeProgress = 0;
        gsap.to(this, 0.8, { _strikeProgress: 1, ease: 'none'});
        if (this._onStrikeCallback) this._onStrikeCallback(this._startPoint, this._endPoint);
    }

    _drawStrike(context) {
        if (this._strikeProgress > 0 && this._strikeProgress < 1) {
            const mainBranch = this._branches.main;
            let item;
            for (let i = 0, len = mainBranch.length; i < len; i++) {
                item = mainBranch[i];

                // const distance = item.startPoint.distanceTo(item.endPoint);
                // const angle = Math.atan2(item.endPoint.y - item.startPoint.y, item.endPoint.x - item.startPoint.x);

                // const textureX = this._texture.width * Math.random();
                // const textureY = this._texture.height * Math.random();

                // context.save();
                // context.translate(item.startPoint.x, item.startPoint.y);
                // context.rotate(angle + -Math.PI * 0.5);

                // const lineWidth = 2;

                // context.globalAlpha = 0.15;
                // context.globalCompositeOperation = 'lighter';
                // context.drawImage(this._texture, textureX, textureY, lineWidth, distance, 0, 0, lineWidth, distance);
                // context.globalCompositeOperation = 'source-over';
                // context.restore();
                // context.globalAlpha = 1;

                context.strokeStyle = 'white';
                context.beginPath();
                context.moveTo(item.startPoint.x, item.startPoint.y);
                context.lineTo(item.endPoint.x, item.endPoint.y);
                context.stroke();
            }
        }
    }

    _drawBranch(context, branch, progress) {
        if (!branch) return;

        let segment;
        let target;
        let segmentPrecent;
        let segmentProgress;
        let length = branch.length;
        const segmentStep = 1 / length;

        const visible = [];

        for (let i = 0; i < length; i++) {
            segment = branch[i];

            if (segment.split) {
                context.lineWidth = this._sideBranchesWidth;
                context.globalAlpha = this._sideBranchesAlpha;
            } else {
                context.lineWidth = this._mainBranchWidth;
                context.globalAlpha = this._mainBranchAlpha;
            }

            segmentPrecent = i / length;
            if (segmentPrecent <= progress) {
                segmentProgress = (progress - segmentPrecent) / segmentStep;
                segmentProgress = this._clamp(segmentProgress, 0, 1);

                target = new Vector2();
                target.lerpVectors(segment.startPoint, segment.endPoint, segmentProgress);

                visible.push(segment);
            }
        }

        const lastItemAmount = -this._strokeLength;
        const items = visible.slice(lastItemAmount);

        let item;
        for (let i = 0, len = items.length; i < len; i++) {
            item = items[i];

            context.strokeStyle = 'white';
            context.beginPath();
            context.moveTo(item.startPoint.x, item.startPoint.y);
            context.lineTo(item.endPoint.x, item.endPoint.y);
            context.stroke();
        }

        context.globalAlpha = 1;

    }

    _clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }

    _randomizeSpeed(value) {
        return value * 0.5 + value * 0.5 * Math.random();
    }
}
