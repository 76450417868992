import SimplexNoise from 'simplex-noise';
import gsap from 'gsap';

export default class Plant {
    constructor({ context, position, size, config, texture, color, globalAlpha, scaleModifier }) {
        this._context = context;
        this._position = position;
        this._size = size;
        this._config = config;
        this._texture = texture;
        this._globalAlpha = globalAlpha;

        this._simplexNoise = new SimplexNoise();
        this._progress = 0;
        this._angle = 0;
        this._scale = 1;
        this._color = color;
        this._scaleModifier = scaleModifier;

        this._noisePosition = this._calcNoiseStartPosition();
        this._radius = this._calcRadius();
        this._direction = this._calcDirection();
        this._alpha = this._calcAlpha();
        this._delay = this._calcDelay(); // seconds

        this._colorAlpha = this._config.colorAlpha;
    }

    /**
     * Getters & Setters
     */
    get position() {
        return this._position;
    }

    /**
     * Public
     */
    update(time) {
        if (time < this._delay) return;

        this._progress += 0.0005; // 0.0005
        this._angle += 0.02 * this._direction;
        this._scale -= this._scaleModifier;
        this._scale = gsap.utils.clamp(0, 1, this._scale);
        this._noisePosition.x += this._config.noiseSpeed;
    }

    draw() {
        if (this._progress > 0 && this._progress < this._config.duration) {
            const noise = this._simplexNoise.noise2D(this._noisePosition.x * 0.5, this._noisePosition.y * 0.5);
            const radius = this._radius * noise;
            const size = this._size * this._scale;
            const x = radius * Math.cos(this._angle);
            const y = radius * Math.sin(this._angle);

            this._context.globalAlpha = this._colorAlpha * this._alpha * this._globalAlpha;
            this._context.strokeStyle = this._color;
            this._context.save();
            // this._context.translate(this._position.x + x - size, this._position.y + y - size);
            this._context.translate(this._position.x + x, this._position.y + y);

            this._context.rotate(Math.PI * 2 * Math.random());

            // this._context.beginPath();
            // this._context.arc(0, 0, size, 0, Math.PI * 2);
            // this._context.stroke();

            this._context.drawImage(this._texture, -size, -size, size * 2, size * 2);

            this._context.restore();
            this._context.globalAlpha = 1;
        }
    }

    /**
     * Private
     */
    _calcNoiseStartPosition() {
        const x = Math.random() * 100;
        const y = Math.random() * 100;
        return { x, y };
    }

    _calcRadius() {
        return this._config.minRadius + Math.random() * (this._config.maxRadius - this._config.minRadius);
    }

    _calcDirection() {
        return Math.random() > 0.5 ? 1 : -1;
    }

    _calcAlpha() {
        return 0.7 + Math.random() * 0.3;
    }

    _calcDelay() {
        return Math.random() * this._config.maxDelay;
        // return 0;
    }
}
